import tw, { css } from "twin.macro"
import styled from "@emotion/styled/macro"

import { StyledButton, StyledLink } from "../../Styled/Button"
import { H5, H6 } from "../../Styled/Text"
import { StyledForm, StyledLabel } from "../../Styled/Form"

export const Subscribe = styled.div<GenericProps>`
  ${tw`relative md:absolute top-10 md:top-20 left-0 bg-orange rounded-r-3xl transition-all duration-100`}
  ${({ active }) => (active ? tw`h-60 md:h-66 w-full md:w-121.5 cursor-pointer md:z-10` : tw`h-15 w-40 cursor-pointer`)}
`
export const SubscribeInner = styled.div`
  ${tw`relative w-full h-full`}
`
export const SubscribeTab = styled.div<GenericProps>`
  ${tw`absolute flex items-center justify-center w-full h-15 transform scale-90 translate-y-6 transition-all duration-100`}
  ${({ active }) => (active ? tw`opacity-100 visible scale-100 translate-y-0` : tw`opacity-0`)}
`
export const SubscribePanel = styled.div<GenericProps>`
  ${tw`absolute flex flex-col items-start justify-start w-full h-full py-4 pl-4 pr-8 invisible transform scale-90 translate-y-6 transition-all duration-100`}
  ${({ active }) => (active ? tw`opacity-100 visible scale-100 translate-y-0` : tw`opacity-0`)}
`
export const Text = tw(H5)`text-white md:text-xl`

export const Form = tw(StyledForm)`flex flex-col items-center`
export const FormTitle = tw(H6)`self-start max-w-92.5`
export const Close = tw(StyledLink)`absolute top-4 right-2 w-4 md:w-5`
export const Button = tw(StyledButton)`self-start text-2xl md:text-2xl`
export const Label = tw(StyledLabel)`text-white self-start mb-8 -mt-6 max-w-92.5`

export const SubscribeSuccessWrapper = tw.div`flex flex-col h-full justify-center`
export const SuccessPanelHeading = tw(H6)`self-start max-w-92.5`
export const SuccessPopupHeading = tw(H5)`text-center max-w-92.5`
export const SuccessPanelText = tw(H6)`self-start max-w-92.5 font-normal`
export const SuccessPopupText = tw(H5)`text-center max-w-92.5 font-normal`
export const SuccessTextSpan = tw.span`font-bold`

export const LinkText = tw(H6)`text-left text-white font-bold mb-12`
export const Span = tw.span`inline-flex ml-2`
export const Link = tw(StyledLink)`self-start`

export const Notify = styled.section<NotifyProps>`
  ${tw`fixed inset-0 flex items-center justify-center z-20 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`
export const Popup = styled.div<GenericProps>`
  ${tw`relative w-full bg-orange text-white rounded-lg shadow px-12 pb-12 pt-16 overflow-x-hidden overflow-y-auto max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const PopupInner = tw.div`flex items-center justify-center w-full h-full p-6 w-129.5 max-w-full`
export const PopupForm = tw(StyledForm)`flex flex-col items-center`
export const PopupClose = tw(StyledLink)`absolute top-6 right-6 w-4 md:w-5`
export const PopupButton = tw(StyledButton)`md:w-47.5`

interface GenericProps {
  active: boolean
}

interface NotifyProps extends GenericProps {
  top?: boolean
}
